/* individual nav items */
.nav-link {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 90px;
    border-bottom: 4px solid transparent;
}

.nav-link:hover {
    color: var(--ksa-orange) !important;
    border-bottom: 4px solid var(--ksa-orange);
}

.active {
    color: var(--ksa-orange) !important;
}


/* KSA logo & icons */
#logo {
    height: 80px;
    padding: 5px;
}

.socialIcon:hover {
    /* filtered to color variable 'KSA-Orange': #ec8622 */
    filter: invert(70%) sepia(97%) saturate(2787%) hue-rotate(345deg) brightness(96%) contrast(93%);
    /* transition: all 0.3s ease-in-out; */
}


/* nav scroll animation */
nav {
    padding: 0 !important;
    transition: all 0.3s ease-in-out;
    margin-bottom: 20px;
}

.scrolled-down {
    transform: translateY(-100%);
}

.scrolled-up {
    transform: translateY(0);
    filter: drop-shadow(0 -10px 20px rgb(170, 170, 170));
}

@media screen and (max-width: 575px) {
    .scrolled-down {
        transform: translateY(0);
    }

    .scrolled-up {
        transform: translateY(0);
    }
}