.banlogo {
    height: 300px;
}

.banWrapper {
    display: flex;
    margin-bottom: 20px;
}

.banWrapper:nth-child(even) {
    flex-direction: row-reverse;
}

.banWrapper p {
    text-align: justify;
    margin-top: 5px;
}

.banWrapper>div {
    margin-left: 20px;
}

.banWrapper:nth-child(even)>div {
    flex-direction: row-reverse;
    margin-left: 0;
    margin-right: 20px;
}

.banWrapper h1,
.banWrapper h2 {
    margin: 0;
}

.banWrapper h2 {
    font-size: 1em;
}

@media screen and (max-width: 766px) {
    .banWrapper {
        flex-direction: column;
    }

    .banWrapper:nth-child(even) {
        flex-direction: column;
    }
}