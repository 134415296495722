* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

:root {
  --icon: red;
  --primary: #6091e7ff;
  --ksa-blue: #4167b0;
  --ksa-orange: #ec8622;
}